import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import close from '../assets/images/close.svg';
import IcnRank from '../assets/images/icn_ranking.svg';
import IconBank from '../assets/images/icon/bank.png';
import chipButtonInfo from '../assets/images/icon/chip_button_info.svg';

// chips
// 1: bunker, 2: 3putt, 3: tree, 4: OB, 5: hazard, 6: triple bogey, 7: road, 8: double par
import chip3putt from '../assets/images/icon/chips_3putt.png';
import chipBunker from '../assets/images/icon/chips_bunker.png';
import chipDoublepar from '../assets/images/icon/chips_doublepar.png';
import chipHazrd from '../assets/images/icon/chips_hazard.png';
import chipOB from '../assets/images/icon/chips_ob.png';
import chipRoad from '../assets/images/icon/chips_road.png';
import chipTree from '../assets/images/icon/chips_tree.png';
import chipTriplebogey from '../assets/images/icon/chips_triplebogey.png';
import crownOrange from '../assets/images/icon/crown_orange.png';
import golinee from '../assets/images/icon/golinee.svg';
import icon3putt from '../assets/images/icon/icon_3putt.png';
import iconBunker from '../assets/images/icon/icon_bunker.png';
import iconDoublepar from '../assets/images/icon/icon_doublepar.png';
import iconHazrd from '../assets/images/icon/icon_hazard.png';
import iconOB from '../assets/images/icon/icon_ob.png';
import iconRoad from '../assets/images/icon/icon_road.png';
import iconTree from '../assets/images/icon/icon_tree.png';
import iconTriplebogey from '../assets/images/icon/icon_triplebogey.png';
import iconJoker from '../assets/images/icon/joker_2.svg';
import stickDefault from '../assets/images/stick_default.gif';
import stickGreen from '../assets/images/stick_green.gif';
import stickJoker from '../assets/images/stick_joker.gif';
import stickOrange from '../assets/images/stick_orange.gif';

import { TopNavigation } from '../components/atoms/TopNavigation';
import { isScoreValue } from '../components/blocks/format';
import { useSiteState } from '../contexts/SiteContext';
import { useSocketDataState } from '../contexts/SocketDataContext';
import DimmedRankingView from './Group/DimmedRankingView';

export default function PlayGame() {
  const [checkData, setCheckData] = useState(false);
  const navigate = useNavigate();
  const {
    data,
    pid,
    sendShotCount,
    resetChips,
    sendChip,
    playerUpdate,
    gameUpdate,
    socketDisconnect,
  } = useSocketDataState();
  const { memberInfo, gameInfo, gameInfoChange } = useSiteState();
  const chipsInfo = [
    { name: '', img: '', icon: '' },
    { name: '벙커', img: chipBunker, icon: iconBunker },
    { name: '쓰리 펏', img: chip3putt, icon: icon3putt },
    { name: '트리', img: chipTree, icon: iconTree },
    { name: '오비', img: chipOB, icon: iconOB },
    { name: '워터 해저드', img: chipHazrd, icon: iconHazrd },
    { name: '트리플 보기', img: chipTriplebogey, icon: iconTriplebogey },
    { name: '카트로드', img: chipRoad, icon: iconRoad },
    { name: '더블 파', img: chipDoublepar, icon: iconDoublepar },
  ];
  const groupCode = localStorage.getItem('GGCD') || '0';
  const stickInfo = [stickDefault, stickGreen, stickOrange, stickJoker];
  const [savegame, setSavegame] = useState(false);
  const [chipPid, setChipPid] = useState(0);
  const [chipView, setChipView] = useState(false);
  const [chipSendPop, setChipSendPop] = useState(false);
  const [chipNm, setChipNm] = useState(0);
  const [chipSend, setChipSend] = useState<any[]>([]);
  const [editHole, setEditHole] = useState(0);
  const [selectPlayer, setSelectPlayer] = useState(0);
  const [puttView, setPuttView] = useState<boolean>(true);
  let pointCn = 0;
  let teamWin = false;
  let allsquare = false;
  const gameMetaData = data?.game_room_metadata || {};
  const gameData = gameMetaData.game_data || {};
  const gameType = gameData?.gameType;
  const playerCount = gameMetaData.max_player_count;
  const playerData = data?.participant_list || [];
  const birdiePoint = gameData?.birdie === '1';
  const [pointView, setPointView] = useState<boolean>(gameType !== 'normal');
  const [optionListNickname, setOptionListNickname] = useState('');
  const [userOptionView, setUserOptionView] = useState(false);
  const [recorderUserID, setRecorderUserID] = useState(0);
  const [listNth, setListNth] = useState();
  let bank = 0;
  playerData?.forEach((item: any) => {
    item.hole_id_list.forEach((hole: any) => {
      bank += hole.bank_calc_value;
    });
  });
  const roundInfo = gameMetaData.hole_list;
  const chipsView = gameType === 'chips';
  const owner = memberInfo?.user_id === Number(gameMetaData.owner_user_id);
  const recorder = gameMetaData.recorder_participant_id_list?.includes(pid);
  const myPlayInfo = playerData?.find(
    (item: any) =>
      item.participant_id === (selectPlayer !== 0 ? selectPlayer : pid),
  );
  const myHoleInfo = myPlayInfo?.hole_id_list || null;
  const myPoint = myHoleInfo?.reduce(
    (sum: any, item: any) => sum + item.point,
    0,
  );
  const myOECD = gameType !== 'chips' && myPoint >= Number(gameData?.OECD);

  const otherPlayerInfo: any = playerData?.filter(
    (participant: any) => participant.participant_id !== pid,
  );
  const nowHole = selectPlayer
    ? playerData?.find((item: any) => item.participant_id === selectPlayer)
        ?.hole_id_list?.length
    : myHoleInfo?.length;
  const nowHoleArr = Math.max(nowHole - 1, 0);
  const [playing, setPlaying] = useState<boolean>(
    nowHole < Number(localStorage.getItem('hs')) ||
      selectPlayer !== 0 ||
      myHoleInfo?.length === 0,
  );

  let smallestLength = Infinity;
  otherPlayerInfo?.forEach((participant: any) => {
    const holeIdListLength = participant.hole_id_list.length;
    if (holeIdListLength < smallestLength) {
      smallestLength = holeIdListLength;
    }
  });

  const drawViewCount = playerData?.reduce((acc: any, item: any) => {
    if (item.game_data.draw[nowHoleArr]?.view === 1) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const preInputPoint =
    (myHoleInfo &&
      (nowHole === 0 ? true : myHoleInfo[nowHoleArr]?.point !== null)) ||
    selectPlayer !== 0;

  const nextHole =
    smallestLength >= nowHole &&
    nowHole < roundInfo?.length &&
    (gameType !== 'drawing'
      ? true
      : drawViewCount === 4 || drawViewCount === 0);

  const drawingNextHole =
    gameType === 'drawing' && myPlayInfo.game_data.draw[nowHoleArr]?.view === 0;

  const chipsNextHole =
    gameType === 'chips' &&
    (nowHole === 9 || nowHole === 18) &&
    playerData?.filter(
      (player: any) => player.hole_id_list[nowHoleArr]?.point === null,
    ).length !== 0;

  const allHoleOut = playerData.every(
    (participant: { hole_id_list: { shot: undefined }[] }) =>
      participant.hole_id_list[nowHoleArr]?.shot !== undefined,
  );

  const [oecdCheckValue, setOecdCheckValue] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const scoreNm = [
    { score: null, putt: null },
    { score: null, putt: null },
    { score: [-1, 0, 1, 2], putt: [0, 1] },
    { score: [-2, -1, 0, 1, 2, 3], putt: [0, 1, 2] },
    { score: [-3, -2, -1, 0, 1, 2, 3, 4], putt: [0, 1, 2, 3] },
    { score: [-4, -3, -2, -1, 0, 1, 2, 3, 4, 5], putt: [0, 1, 2, 3, 4] },
    {
      score: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6],
      putt: [0, 1, 2, 3, 4, 5],
    },
    {
      score: [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7],
      putt: [0, 1, 2, 3, 4, 5, 6],
    },
  ];

  // 소켓 기본 입력 데이터
  const baseData = {
    game_room_id: gameInfo?.game_room_id,
    participant_id: myPlayInfo?.participant_id,
    user_nickname: myPlayInfo?.user_nickname,
  };

  const [shotCnt, setShotCnt] = useState<number>(0);
  const [puttCnt, setPuttCnt] = useState<number | null>(null);

  const shotState = (shot: number | '+' | '-') => {
    let v = shot;
    if (typeof shot !== 'number') {
      if (shot === '+') {
        v = shotCnt + 1;
      } else {
        v = shotCnt <= 1 ? 1 : shotCnt - 1;
      }
    }

    if (myOECD) {
      if (Number(v) - roundInfo[nowHole].par >= 3 && !oecdCheckValue[3])
        oecdCheck(3);
      if (Number(v) - roundInfo[nowHole].par < 3 && oecdCheckValue[3])
        oecdCheck(3);
    }

    setShotCnt(Number(v));
  };

  const puttState = (putt: number | '+' | '-') => {
    let v = putt;
    if (typeof putt !== 'number') {
      if (putt === '+') {
        v = Number(puttCnt) + 1;
      } else {
        v = Number(puttCnt) <= 0 ? 0 : Number(puttCnt) - 1;
      }
    }

    if (myOECD) {
      if (Number(v) >= 3 && !oecdCheckValue[2]) oecdCheck(2);
      if (Number(v) < 3 && oecdCheckValue[2]) oecdCheck(2);
    }

    setPuttCnt(Number(v));
  };

  const sendChipsPoint = () => {
    playerData.forEach((player: any) => {
      player.hole_id_list[nowHoleArr].point = -player.game_data.chips.length;
      player.hole_id_list[nowHoleArr].bank_calc_value =
        player.game_data.chips.length;

      const payload = {
        game_room_id: gameInfo?.game_room_id,
        participant_id: player.participant_id,
        user_nickname: player.user_nickname,
        message: { ...player },
      };
      playerUpdate(payload);
    });
    if (nowHole === 9) setTimeout(() => resetChips(), 1000);
  };

  let strokePointCheck = false;
  const sendStrokePoint = (type: any) => {
    const holepar = roundInfo[nowHoleArr]?.par;
    const isRigged = roundInfo[nowHoleArr]?.is_rigged;

    if ((owner || recorder) && !strokePointCheck) {
      playerData.forEach((player: any, i: number) => {
        let pointCn = 0;
        const playerID = player.participant_id;
        const playerHoleInfo = player.hole_id_list[nowHoleArr];
        const oecdPoint = playerHoleInfo.oecd?.filter(Boolean).length;
        const otherPlayer = playerData.filter(
          (playerList: any) => playerList.participant_id !== playerID,
        );
        const playerShot = playerHoleInfo.shot;
        const playerPar = playerShot - holepar;
        const myMinusShot = birdiePoint && playerPar < 0;

        otherPlayer.forEach((oPlayer: any, i: number) => {
          const otherShot = oPlayer.hole_id_list[nowHoleArr]?.shot;
          const otherPar = otherShot - holepar;
          const otherMinusShot = birdiePoint && otherPar < 0;
          const shot = playerShot - otherShot;
          let isDouble = playerPar < 0 || otherPar < 0;

          if (gameType === 'stroke') {
            const countByShot: any = {};
            playerData?.forEach((item: any) => {
              const shot = item.hole_id_list[nowHoleArr]?.shot;
              if (shot) {
                countByShot[shot] = (countByShot[shot] || 0) + 1;
                if (countByShot[shot] >= 3) {
                  isDouble = true;
                }
              }
            });
          }

          const points =
            (shot < 0 ? -shot : shot) * (isDouble ? 2 : 1) * isRigged +
            Number(shot !== 0 && (myMinusShot || otherMinusShot ? 1 : 0));
          pointCn += points * (shot > 0 ? 1 : -1);

          //console.log('otherPalyer : ', oPlayer, i, pointCn);
        });

        playerHoleInfo.point = -(pointCn + oecdPoint);
        playerHoleInfo.bank_calc_value = oecdPoint;

        if (type === 'save') player.exit_yn = 1;
        const payload = {
          ...baseData,
          participant_id: playerID,
          user_nickname: player.user_nickname,
          message: { ...player },
        };
        playerUpdate(payload);
      });

      strokePointCheck = true;
    }

    if (owner || recorder)
      if (allsquare && nowHole !== roundInfo.length) {
        roundInfo[nowHole].is_rigged = isRigged + 1;
        const gamePayload = {
          ...baseData,
          message: {
            ...data.game_room_metadata,
            game_data: {
              ...gameData,
            },
          },
        };
        gameUpdate(gamePayload);
      }
  };

  const holePlay = () => {
    if (gameType === 'drawing') {
      const oecdPoint = myHoleInfo[nowHoleArr].oecd.filter(Boolean).length;
      const playerPoint = (teamWin ? 1 : 0) * roundInfo[nowHoleArr].is_rigged;

      myHoleInfo[nowHoleArr].point = playerPoint - oecdPoint;
      myHoleInfo[nowHoleArr].bank_calc_value = playerPoint - oecdPoint;
      const payload = {
        ...baseData,
        message: { ...myPlayInfo },
      };
      playerUpdate(payload);

      if (owner)
        if (allsquare && nowHole !== roundInfo.length) {
          roundInfo[nowHole].is_rigged = roundInfo[nowHoleArr].is_rigged + 1;
          const gamePayload = {
            ...baseData,
            message: {
              ...data.game_room_metadata,
              game_data: {
                ...gameData,
              },
            },
          };
          gameUpdate(gamePayload);
        }
    }

    if (gameType === 'chips') {
      if (nowHole === 9 || nowHole === 18) {
        if (owner) {
          sendChipsPoint();
        }
      } else {
        myHoleInfo[nowHoleArr].point = 0;
        const payload = {
          ...baseData,
          message: { ...myPlayInfo },
        };
        playerUpdate(payload);
      }
    }

    if (gameType === 'normal') {
      myHoleInfo[nowHoleArr].point = 0;
      myHoleInfo[nowHoleArr].bank_calc_value = 0;
      const payload = {
        ...baseData,
        message: { ...myPlayInfo },
      };
      playerUpdate(payload);
    }

    localStorage.setItem('hs', nowHole + 1);
    setOecdCheckValue([false, false, false, false, false]);
    pointCn = 0;
    teamWin = false;
    allsquare = false;
    setPlaying(nowHole < roundInfo?.length);
  };

  const saveGame = () => {
    console.log('-- Save Game --', owner, chipsNextHole);

    if (owner && chipsNextHole) {
      if (
        confirm(
          '칩 분배가 완료 되었습니까?\n아직 확인 전이라면 취소를 눌러주세요.',
        )
      ) {
        sendChipsPoint();
        setSavegame(true);
      }
      return;
    }

    const nullPointData = otherPlayerInfo.filter(
      (item: any) => item.hole_id_list[nowHoleArr].point === null,
    );

    if (nullPointData.length !== 0 && owner && savegame) {
      alert(
        '현재 방장이십니다.\n다른 플레이어들이 라운드 종료를 완료한 후에 데이터를 저장해주세요.',
      );
      return;
    }

    if ((savegame || gameType === 'chips') && nowHole >= roundInfo?.length) {
      if (
        confirm(
          gameType === 'normal'
            ? '게임을 정말 나가시겠습니까?'
            : '게임을 나가시면 정산된 포인트를 다시 보실 수 없습니다.\n포인트 확인 전이라면 \n취소를 눌러 포인트를 확인해주세요.',
        )
      ) {
        if (owner) {
          restApi
            .put('/game/close-game-room', {
              game_room_id: gameMetaData.game_room_id,
              golf_club_id: gameInfo.golf_club_id,
              participant_list: playerData,
            })
            .then((e) => {
              if (e.data.status_code === '505')
                alert('게임방 정보를 찾을 수 없습니다.');
            });
        }
        socketDisconnect();
        localStorage.removeItem('ri');
        localStorage.removeItem('room_code');
        navigate('/clubhouse');
        return;
      }
    }

    if (gameType === 'stroke' && !savegame) {
      sendStrokePoint('save');
      setSavegame(true);
    }

    if (gameType === 'drawing' && !savegame) {
      holePlay();
      setSavegame(true);
    }

    if (gameType === 'normal' && !savegame) {
      myPlayInfo.hole_id_list[nowHoleArr].point = 0;
      myPlayInfo.hole_id_list[nowHoleArr].bank_calc_value = 0;
      const payload = {
        ...baseData,
        message: { ...myPlayInfo },
      };
      playerUpdate(payload);
      setSavegame(true);
    }
  };

  const holeOut = () => {
    if (!shotCnt) {
      alert('샷횟수가 입력되지 않았습니다.');
      return false;
    }
    if (
      !(puttCnt || puttCnt === 0) &&
      puttView &&
      !confirm('퍼팅횟수가 입력되지 않았습니다.\n그대로 진행할까요?')
    ) {
      return false;
    }
    if (shotCnt <= Number(puttCnt)) {
      alert('퍼팅횟수가 총횟수보다 많습니다.\n확인해주세요.');
      return false;
    }

    let editPlayerInfo;
    if (selectPlayer) {
      editPlayerInfo = playerData?.find(
        (item: any) => item.participant_id === selectPlayer,
      );
    }

    const playerInfo = selectPlayer ? editPlayerInfo : myPlayInfo;
    const playerHoleInfo = playerInfo?.hole_id_list || null;

    const holeinfo = {
      ...playerInfo,
      hole_id_list: [
        ...playerHoleInfo,
        {
          hole_id: roundInfo[nowHole].hole_id,
          shot: shotCnt,
          putt: puttCnt,
          score: shotCnt - roundInfo[nowHole].par,
          point: null,
          oecd: oecdCheckValue,
          other_w: selectPlayer ? pid : 0,
        },
      ],
    };

    sendShotCount(holeinfo); // 소켓 메시지 전송
    // 점수 입력 완료 & 값 초기화 --
    setShotCnt(0);
    setPuttCnt(null);
    setPlaying(false);
    setEditHole(0);
    setSelectPlayer(0);
  };

  const ViewChip = (pid: number) => {
    setChipPid(pid);
    setChipView(true);
  };

  const CloseChip = () => {
    setChipSendPop(false);
    setChipView(false);
  };

  const oecdCheck = (oecd: number) => {
    setOecdCheckValue((prevState: any) => {
      const newState = [...prevState];
      newState[oecd] = !oecdCheckValue[oecd];
      return newState;
    });
  };

  const userOptionListView = (nm: number, nickname: string, userId: number) => {
    if (owner && gameMetaData.owner_participant_id !== userId) {
      setUserOptionView(true);
      setOptionListNickname(nickname);
      setRecorderUserID(userId);
      setListNth(
        playerData.findIndex(
          (player: any) => player.participant_id === userId,
        ) + 1,
      );
    }
  };

  const userOptionListClose = () => {
    setUserOptionView(false);
  };

  const insertRecorderUser = () => {
    const recorderUser = gameMetaData.recorder_participant_id_list;
    const userList = recorderUser?.includes(recorderUserID)
      ? recorderUser.filter((nm: number) => {
          return nm !== recorderUserID;
        })
      : recorderUser
      ? [...recorderUser, recorderUserID]
      : [recorderUserID];

    const gamePayload = {
      ...baseData,
      message: {
        ...gameMetaData,
        recorder_participant_id_list: userList.filter(
          (v: any, i: any) => userList.indexOf(v) === i,
        ),
      },
    };
    gameUpdate(gamePayload);
    setUserOptionView(false);
  };

  useEffect(() => {
    const roomCode = localStorage.getItem('room_code');
    if (roomCode && !gameInfo) {
      restApi
        .get(`/guest/check-room-code`, {
          params: {
            room_code: roomCode,
          },
        })
        .then((e) => {
          if (e.data.status_code === '505') {
            alert(
              '방이 종료 되었습니다.\n게임 정보에서 데이터를 확인해주세요.',
            );
            navigate('/clubhouse');
            return;
          }
          localStorage.setItem('ri', e.data.result_data);
          restApi
            .get(`/guest/game/detail`, {
              params: {
                game_room_id: e.data.result_data,
              },
            })
            .then((e) => {
              gameInfoChange(e.data.result_data);
            });
        });
    }

    return () => {
      localStorage.removeItem('ri');
      localStorage.removeItem('room_code');
      localStorage.removeItem('GGID');
      localStorage.removeItem('GGCD');
      socketDisconnect();
    };
  }, []);

  useEffect(() => {
    if (!checkData && !!myHoleInfo) {
      setPlaying(nextHole && nowHole < Number(localStorage.getItem('hs')));
      setCheckData(true);
    } else {
      setPlaying(
        nowHole === 0 ||
          nowHole < Number(localStorage.getItem('hs')) ||
          selectPlayer !== 0,
      );
    }
  }, [myHoleInfo]);

  useEffect(() => {
    if (data && savegame && preInputPoint) {
      playerData.sort((a: any, b: any) => {
        const sumA = a.hole_id_list.reduce(
          (sum: any, hole: any) => sum + hole.shot,
          0,
        );
        const sumB = b.hole_id_list.reduce(
          (sum: any, hole: any) => sum + hole.shot,
          0,
        );

        return sumA - sumB;
      });
    }
  }, [data]);

  const SendChip = () => {
    const info = playerData?.find(
      (item: any) => item.participant_id === chipPid,
    );

    const chipLeft = chipNm > 4 ? chipNm - 4 : chipNm;
    const chipTop = chipNm > 4 ? 270 : 160;
    const leftLoc = (chipLeft === 4 ? 2.8 : chipLeft) * 90 - 30;
    const topLoc = chipTop;

    return (
      <>
        <Dimmed onClick={CloseChip}>
          <ChipBox
            onClick={(e) => {
              e.stopPropagation();
              setChipSendPop(false);
            }}
          >
            <div style={{ paddingLeft: '12px', marginBottom: '10px' }}>
              <Typography
                variant="h5Bold"
                style={{ display: 'block', marginBottom: '19px' }}
              >
                칩 현황
              </Typography>
              <Typography variant="body1">
                보유&nbsp;
                <strong style={{ color: '#1fb04b' }}>
                  {info?.game_data?.chips?.length}
                </strong>
              </Typography>
              <Typography variant="h4Bold">{info?.user_nickname}</Typography>
              <ImgClose src={close} onClick={CloseChip} alt="Close Button" />
            </div>
            <ChipList>
              {info?.game_data?.chips?.map((chip: number, i: number) => {
                return (
                  <Chip
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        (info.participant_id === myPlayInfo.participant_id &&
                          (!preInputPoint || myHoleInfo.length < 1)) ||
                        owner
                      ) {
                        setChipSendPop(true);
                        setChipSend([chip, info.participant_id]);
                        setChipNm(i + 1);
                      }
                    }}
                  >
                    <img src={chipsInfo[chip].img} alt={chipsInfo[chip].name} />
                    <div>{chipsInfo[chip].name}</div>
                  </Chip>
                );
              })}
              {chipSendPop && (
                <OptionListTable
                  style={{ top: `${topLoc}px`, left: `${leftLoc}px` }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <img
                          src={chipsInfo[chipSend[0]].icon}
                          alt={chipsInfo[chipSend[0]].name}
                        />
                        {chipsInfo[chipSend[0]].name} 칩을
                      </TableCell>
                    </TableRow>
                    {playerData?.map((player: any) => {
                      return player.participant_id !== chipSend[1] ? (
                        <TableRow key={player.participant_id}>
                          <TableCell
                            onClick={() =>
                              sendChip(
                                chipSend[1],
                                player.participant_id,
                                chipSend[0],
                              )
                            }
                          >
                            {player.user_nickname}에게 보내기
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      );
                    })}
                  </TableBody>
                </OptionListTable>
              )}
            </ChipList>
            <ChipInfo>
              {preInputPoint && myHoleInfo.length > 1
                ? '정산이 완료 된 후에는 칩을 이동하실 수 없습니다.'
                : info?.participant_id !== myPlayInfo?.participant_id && !owner
                ? '다른 플레이어의 칩은 확인만 가능합니다.'
                : '칩을 탭하면 해당 칩을 다른 플레이어에게 보낼 수 있어요!'}
            </ChipInfo>
          </ChipBox>
        </Dimmed>
      </>
    );
  };

  const drawView = (hole: number) => {
    const gameData: any = myPlayInfo.game_data;
    const updatedDraw = [...gameData.draw];
    updatedDraw[hole] = { ...updatedDraw[hole], view: 1 };

    const updatedGameData = { ...gameData, draw: updatedDraw };

    const message = {
      ...myPlayInfo,
      game_data: updatedGameData,
    };

    const payload = {
      game_room_id: gameInfo.game_room_id,
      participant_id: myPlayInfo.participant_id,
      user_nickname: myPlayInfo.user_nickname,
      message: message,
    };

    playerUpdate(payload);
  };

  const HoleOutOECD = () => {
    return (
      <HoleOutInfo className="oecd">
        OECD가 적용되어 {myHoleInfo[nowHoleArr].oecd?.filter(Boolean).length}
        PT가 BANK에 적립됩니다.
      </HoleOutInfo>
    );
  };

  const HoleOutStroke = () => {
    const holepar = roundInfo[nowHoleArr]?.par;
    const myShot = myHoleInfo[nowHoleArr]?.shot;
    const myPar = myShot - holepar;
    const myMinusShot = birdiePoint && myPar < 0;
    const isRigged = roundInfo[nowHoleArr]?.is_rigged;
    pointCn = myHoleInfo[nowHoleArr]?.oecd?.filter(Boolean).length;
    allsquare = playerData?.every(
      (player: any) =>
        player.hole_id_list[nowHoleArr]?.shot ===
        playerData[0].hole_id_list[nowHoleArr]?.shot,
    );

    return (
      <>
        {otherPlayerInfo.map((v: any, i: number) => {
          const otherShot = v.hole_id_list[nowHoleArr]?.shot;
          const otherPar = otherShot - holepar;
          const otherMinusShot = birdiePoint && otherPar < 0;
          const shot = myShot - otherShot;
          const otherName = v.user_nickname;
          const st = shot > 0 ? '-' : '+';
          //let isDouble = myPar >= 3 || myPar < 0 || otherPar >= 3 || otherPar < 0;//트리플 계산 포함
          let isDouble = myPar < 0 || otherPar < 0;

          if (gameType === 'stroke') {
            const countByShot: any = {};
            playerData?.forEach((item: any) => {
              const shot = item.hole_id_list[nowHoleArr]?.shot;
              if (shot) {
                countByShot[shot] = (countByShot[shot] || 0) + 1;
                if (countByShot[shot] >= 3) {
                  isDouble = true;
                }
              }
            });
          }

          const points =
            (shot < 0 ? -shot : shot) * (isDouble ? 2 : 1) * isRigged +
            Number(shot !== 0 && (myMinusShot || otherMinusShot ? 1 : 0));

          pointCn += points * (shot > 0 ? 1 : -1);

          if (
            otherPlayerInfo.length - 1 === i &&
            allHoleOut &&
            myHoleInfo[nowHoleArr].point === null
          ) {
            sendStrokePoint('play');
          }

          if (shot === 0) {
            return (
              <StrokePointInfo key={i}>
                <StrokePlayerList>
                  <strong>{`${otherName}님`}</strong>이{' '}
                  {isScoreValue(otherPar).txt}를(을) 기록
                </StrokePlayerList>
                <PointStatus>동점</PointStatus>
              </StrokePointInfo>
            );
          } else {
            return (
              <StrokePointInfo key={i}>
                <StrokePlayerList>
                  <strong>{`${otherName}님`}</strong>이{' '}
                  {isScoreValue(otherPar).txt}를(을) 기록
                </StrokePlayerList>
                <PointStatus>
                  <span className={shot > 0 ? 'o' : 'g'}>
                    {st}
                    {points}PT
                  </span>
                </PointStatus>
              </StrokePointInfo>
            );
          }
        })}
        {myHoleInfo[nowHoleArr]?.oecd?.filter(Boolean).length > 0 && (
          <HoleOutOECD />
        )}
        <HoleOutInfo className={pointCn > 0 ? 'total o' : 'total g'}>
          {pointCn === 0 ? (
            <>이번홀은 포인트 변동이 없습니다.</>
          ) : (
            <>
              총{' '}
              {pointCn < 0 ? (
                <span className="g">
                  <strong>{Math.abs(pointCn)}PT</strong> 획득
                </span>
              ) : (
                <span className="o">
                  <strong>{Math.abs(pointCn)}PT</strong> 유실
                </span>
              )}
              했습니다.
            </>
          )}
        </HoleOutInfo>
      </>
    );
  };

  const HoleOutDraw = () => {
    const team = myPlayInfo?.game_data.draw[nowHoleArr]?.team;
    const view = myPlayInfo?.game_data.draw[nowHoleArr]?.view;

    const teamTxt = () => {
      switch (team) {
        case 1:
          return (
            <>
              <span style={{ color: '#0bc49a' }}>청록팀!</span> 당첨
            </>
          );
        case 2:
          return (
            <>
              <span style={{ color: '#ff9500' }}>주황팀!</span> 당첨
            </>
          );
        case 3:
          return (
            <>
              <span>조커!</span> 당첨
            </>
          );
        default:
          return '에러';
      }
    };

    const title = view === 1 ? teamTxt() : '뽑기를 뽑아볼까요!';
    const subTitle =
      view === 1
        ? '다른 플레이어가 뽑기를 기다리고 있습니다.'
        : '스틱을 클릭하시면 팀이 정해집니다.';

    // 팀 맺기
    const teamGreen: any = playerData?.filter(
      (player: any) => player.game_data.draw[nowHoleArr]?.team === 1,
    );
    const teamOrange: any = playerData?.filter(
      (player: any) => player.game_data.draw[nowHoleArr]?.team === 2,
    );
    const joker = playerData?.find(
      (player: any) => player.game_data.draw[nowHoleArr]?.team === 3,
    );

    if (teamGreen?.length === 1) {
      teamGreen.push(joker);
    } else if (teamOrange.length === 1) {
      teamOrange.push(joker);
    }

    const getShotValue = (player: any) => {
      const team = player?.game_data.draw[nowHoleArr]?.team;
      const shot = player?.hole_id_list[nowHoleArr]?.shot;
      const par = roundInfo[nowHoleArr]?.par;
      const joker = Number(gameData?.joker);

      return team === 3 ? joker + par : shot;
    };

    const green1p = getShotValue(teamGreen[0]);
    const green2p = getShotValue(teamGreen[1]);
    const orange1p = getShotValue(teamOrange[0]);
    const orange2p = getShotValue(teamOrange[1]);

    const teamGreenPoint = green1p + green2p - roundInfo[nowHoleArr]?.par * 2;
    const teamOrangePoint =
      orange1p + orange2p - roundInfo[nowHoleArr]?.par * 2;

    allsquare = teamGreenPoint === teamOrangePoint;

    if (!allsquare) {
      if (teamGreenPoint < teamOrangePoint) {
        teamWin = !!teamGreen?.find(
          (player: any) => player?.participant_id === pid,
        );
      } else {
        teamWin = !!teamOrange?.find(
          (player: any) => player?.participant_id === pid,
        );
      }
    }

    return (
      <HoleOutInfo style={{ textAlign: 'center' }}>
        {drawViewCount !== 4 ? (
          <>
            <Typography variant="h5Bold">{title}</Typography>
            <Typography variant="body1">{subTitle}</Typography>
            <img
              src={view === 1 ? stickInfo[team] : stickInfo[0]}
              onClick={() => {
                if (view === 0) drawView(nowHoleArr);
              }}
              alt="stick"
            />
          </>
        ) : !savegame ? (
          <>
            <TeamBox container gap={1}>
              <Grid item xs>
                {teamGreen.map((player: any) => {
                  const joker = player.game_data.draw[nowHoleArr].team === 3;
                  const score =
                    player.hole_id_list[nowHoleArr]?.shot -
                    roundInfo[nowHoleArr]?.par;
                  return (
                    <PlayerList key={player.participant_id}>
                      <PlayerBox className="team1">
                        {joker && (
                          <img
                            src={iconJoker}
                            style={{ width: '18px' }}
                            alt="icon"
                          />
                        )}
                      </PlayerBox>
                      <PlayerName
                        className={
                          player.participant_id === myPlayInfo.participant_id
                            ? 'me'
                            : 'other'
                        }
                      >
                        {player.user_nickname}
                      </PlayerName>
                      <PlayerPoint>
                        {!joker && score > 0 && '+'}
                        {joker ? '조커' : score}
                      </PlayerPoint>
                    </PlayerList>
                  );
                })}
              </Grid>
              <Result item xs={3} className="team1">
                {teamGreenPoint === teamOrangePoint
                  ? '동점'
                  : teamGreenPoint < teamOrangePoint
                  ? '승리'
                  : '패배'}
                <TeamPoint>
                  {teamGreenPoint > 0 && '+'}
                  {teamGreenPoint}
                </TeamPoint>
              </Result>
            </TeamBox>
            <TeamBox container gap={1}>
              <Grid item xs>
                {teamOrange.map((player: any) => {
                  const joker = player.game_data.draw[nowHoleArr].team === 3;
                  const score =
                    player.hole_id_list[nowHoleArr]?.shot -
                    roundInfo[nowHoleArr]?.par;
                  return (
                    <PlayerList key={player.participant_id}>
                      <PlayerBox className="team2">
                        {joker && (
                          <img
                            src={iconJoker}
                            style={{ width: '18px' }}
                            alt="icon"
                          />
                        )}
                      </PlayerBox>
                      <PlayerName
                        className={
                          player.participant_id === myPlayInfo.participant_id
                            ? 'me'
                            : 'other'
                        }
                      >
                        {player.user_nickname}
                      </PlayerName>
                      <PlayerPoint>
                        {!joker && score > 0 && '+'}
                        {joker ? '조커' : score}
                      </PlayerPoint>
                    </PlayerList>
                  );
                })}
              </Grid>
              <Result item xs={3} className="team2">
                {teamGreenPoint === teamOrangePoint
                  ? '동점'
                  : teamGreenPoint > teamOrangePoint
                  ? '승리'
                  : '패배'}
                <TeamPoint>
                  {teamOrangePoint > 0 && '+'}
                  {teamOrangePoint}
                </TeamPoint>
              </Result>
            </TeamBox>
          </>
        ) : (
          <TeamBox>
            <div>게임종료</div>
          </TeamBox>
        )}
      </HoleOutInfo>
    );
  };

  const editHoleHandler = (pid: number, hole: number) => {
    const aa = playerData?.find((item: any) => item.participant_id === pid)
      .hole_id_list.length;

    if (owner || recorder) {
      setEditHole(hole);
      setSelectPlayer(pid);
      setPlaying(true);
      setOecdCheckValue([false, false, false, false, false]);
    }
  };

  const [rankView, setRankView] = useState(false);
  const RankingView = () => setRankView(true);

  return (
    <Container component="main" sx={{ paddingBottom: '100px' }}>
      <TopNavigation type="back" handleEvent={() => navigate('/clubhouse')}>
        {gameMetaData.title}
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mb: '10px',
          }}
        >
          <OptionOnOff
            className={puttView ? 'on' : 'off'}
            onClick={() => setPuttView(!puttView)}
          >
            퍼팅 {puttView ? 'ON' : 'OFF'}
          </OptionOnOff>
          {/*{gameType !== 'normal' && (
            <OptionOnOff
              className={pointView ? 'on' : 'off'}
              onClick={() => setPointView(!pointView)}
            >
              포인트 {pointView ? 'ON' : 'OFF'}
            </OptionOnOff>
          )}
          <SwitchButton
            control={
              <Switch
                checked={puttView}
                onChange={() => setPuttView(!puttView)}
                name="Putt"
                color="primary"
                disabled={compView}
              />
            }
            label="Putt"
            labelPlacement="start"
          />
          <SwitchButton
            control={
              <Switch
                checked={compView}
                onChange={() => setCompView(!compView)}
                name="Comp"
                color="primary"
              />
            }
            label="타수 차이"
            labelPlacement="start"
          />*/}
        </Box>

        <ScoreBoard>
          <StyledTable stickyHeader sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 113 }} />
                {pointView && (
                  <TableCell
                    sx={{
                      width: 70,
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#3162d2',
                    }}
                  >
                    POINT
                  </TableCell>
                )}
                {chipsView && (
                  <TableCell
                    sx={{
                      width: 70,
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#3162d2',
                    }}
                  >
                    CHIPS
                  </TableCell>
                )}
                {roundInfo?.map((v: any, i: number) => {
                  return (
                    <TableCell
                      key={i}
                      sx={{
                        width: 52,
                      }}
                    >
                      {i + 1}H
                    </TableCell>
                  );
                })}
                <TableCell sx={{ width: 90 }} style={{ color: '#1fb04b' }}>
                  TOTAL
                </TableCell>
              </TableRow>
              {/*<TableRow>
                <TableStickyTypeCell>PAR</TableStickyTypeCell>
                <TableStickyTypeCell></TableStickyTypeCell>
                {roundInfo.map((v, i) => {
                  return (
                    <TableStickyTypeCell
                      key={i}
                      sx={{
                        backgroundColor:
                          players[0].playHole === i + 1 ? '#ddf' : '',
                      }}
                    >
                      {v}
                    </TableStickyTypeCell>
                  );
                })}
                <TableStickyTypeCell>{ArraySum(roundInfo)}</TableStickyTypeCell>
              </TableRow>*/}
            </TableHead>
            <TableBody style={{ overflowY: 'unset' }}>
              {data &&
                data.participant_list.map((v: any, n: number) => {
                  const totalShot = v.hole_id_list.reduce(
                    (sum: number, item: any) => sum + item.shot,
                    0,
                  );
                  const totalPoint = v.hole_id_list.reduce(
                    (sum: number, item: any) => sum + item.point,
                    0,
                  );
                  const oecd =
                    gameType !== 'chips' &&
                    totalPoint >= Number(gameData?.OECD);

                  return (
                    <TableRow
                      key={n}
                      style={{
                        opacity:
                          editHole && selectPlayer !== v.participant_id
                            ? 0.4
                            : 1,
                      }}
                    >
                      <TableCell
                        onClick={() =>
                          userOptionListView(
                            n,
                            v.user_nickname,
                            v.participant_id,
                          )
                        }
                      >
                        <NameWrap
                          className={v.participant_id === pid ? 'me' : 'other'}
                        >
                          {v.participant_id ===
                            gameInfo.owner_participant_id && (
                            <NameImg src={crownOrange} alt="Me" />
                          )}
                          {oecd && <OECD>OECD</OECD>}
                          {v.user_nickname}
                        </NameWrap>
                      </TableCell>
                      {pointView && (
                        <TableCell>
                          <PointWrap>
                            <PointBox>
                              {(gameType === 'drawing' ? -10 : 0) +
                                v.hole_id_list.reduce(
                                  (sum: number, item: any) => sum + item.point,
                                  0,
                                )}
                            </PointBox>
                          </PointWrap>
                        </TableCell>
                      )}
                      {chipsView && (
                        <TableCell>
                          <PointWrap>
                            <PointBox
                              onClick={() => ViewChip(v.participant_id)}
                            >
                              {v?.game_data?.chips?.length || 0}
                            </PointBox>
                          </PointWrap>
                        </TableCell>
                      )}
                      {roundInfo?.map((cv: any, i: number) => {
                        const shot = v.hole_id_list[i]?.shot || '-';
                        const score = shot - cv?.par;
                        return (
                          <TableCell
                            key={i}
                            className="shotInfo"
                            style={{ position: 'relative' }}
                            onClick={() =>
                              editHoleHandler(v.participant_id, i + 1)
                            }
                          >
                            <ShotWrap>
                              <ShotBox
                                className={
                                  (v.hole_id_list?.length === i
                                    ? 'playHole'
                                    : 'hole') + (score < 0 ? ' under' : ' none')
                                }
                              >
                                <div className="parInfo">par{cv?.par}</div>
                                {isNaN(score)
                                  ? '-'
                                  : score > 0
                                  ? `+${score}`
                                  : score}
                                {v.hole_id_list[i]?.putt !== undefined &&
                                  puttView && (
                                    <Putt key={i}>
                                      {v.hole_id_list[i]?.putt}
                                    </Putt>
                                  )}
                              </ShotBox>
                            </ShotWrap>
                          </TableCell>
                        );
                      })}
                      <TableCell style={{ position: 'relative' }}>
                        <TotalWrap>
                          <TotalBox>
                            {totalShot}
                            {puttView && (
                              <Putt className="total">
                                {v.hole_id_list.reduce(
                                  (sum: number, item: { putt: any }) =>
                                    sum + item.putt,
                                  0,
                                )}
                              </Putt>
                            )}
                          </TotalBox>
                        </TotalWrap>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </ScoreBoard>
        {userOptionView && (
          <>
            <UserOptionList className={`n${listNth}`}>
              <OptionListTable>
                <TableBody>
                  <TableRow onClick={() => userOptionListClose()}>
                    <TableCell style={{ padding: '10px' }}>
                      {optionListNickname}
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => insertRecorderUser()}>
                    <TableCell>
                      기록 권한{' '}
                      {gameMetaData.recorder_participant_id_list?.includes(
                        recorderUserID,
                      )
                        ? '해제'
                        : '승인'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </OptionListTable>
            </UserOptionList>
            <Dimmed
              className="transparent"
              onClick={() => userOptionListClose()}
            />
          </>
        )}
      </Box>

      {!savegame && (
        <HoleInfo variant="h4Bold">
          {nowHole + 1 - (playing && preInputPoint ? 0 : 1)}
          HOLE
          {roundInfo &&
            roundInfo[nowHole - (playing && preInputPoint ? 0 : 1) || 0]
              ?.is_rigged > 1 && (
              <AllsquareDouble>
                {
                  roundInfo[nowHole - (playing && preInputPoint ? 0 : 1) || 0]
                    ?.is_rigged
                }
                배
              </AllsquareDouble>
            )}
          {selectPlayer !== 0 && (
            <SelectedPlayer>
              {
                playerData?.find(
                  (item: any) => item.participant_id === selectPlayer,
                )?.user_nickname
              }
            </SelectedPlayer>
          )}
        </HoleInfo>
      )}

      {playing && preInputPoint && (
        <div>
          <h3>Shot</h3>
          <ShotButtonBox container gap={1}>
            <Grid item xs={2}>
              <CntButton variant="outlined" onClick={() => shotState('-')}>
                -
              </CntButton>
            </Grid>
            <Grid item xs={6}>
              <ScoreInput
                type="number"
                value={shotCnt}
                onChange={(event) => {
                  setShotCnt(Number(event.target.value));
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <CntButton variant="outlined" onClick={() => shotState('+')}>
                +
              </CntButton>
            </Grid>
          </ShotButtonBox>
          <ShotButtonBox container gap={1}>
            {data &&
              scoreNm[roundInfo[nowHole || 0]?.par]?.score?.map((shot, i) => {
                return (
                  <Grid item xs key={shot}>
                    <CntButton
                      className={`dn${shotCnt - 1 === i ? ' selected' : ''}`}
                      variant={shotCnt - 1 === i ? 'contained' : 'outlined'}
                      onClick={() => shotState(i + 1)}
                    >
                      {shot === 0 ? 'P' : shot > 0 ? '+' + shot : shot}
                    </CntButton>
                  </Grid>
                );
              })}
          </ShotButtonBox>
          {myOECD && (
            <OECDBox container gap={1}>
              <OECDList
                item
                xs
                onClick={() => oecdCheck(0)}
                className={oecdCheckValue[0] ? 'on' : 'none'}
              >
                OB
              </OECDList>
              <OECDList
                item
                xs
                onClick={() => oecdCheck(1)}
                className={oecdCheckValue[1] ? 'on' : 'none'}
              >
                벙커
              </OECDList>
              <OECDList
                item
                xs
                onClick={() => oecdCheck(2)}
                className={oecdCheckValue[2] ? 'on' : 'none'}
              >
                쓰리 펏
              </OECDList>
              <OECDList item xs className={oecdCheckValue[3] ? 'on' : 'none'}>
                트리플
              </OECDList>
              <OECDList
                item
                xs
                onClick={() => oecdCheck(4)}
                className={oecdCheckValue[4] ? 'on' : 'none'}
              >
                해저드
              </OECDList>
            </OECDBox>
          )}

          {puttView && (
            <>
              <h3 style={{ marginTop: '30px' }}>Putt</h3>
              <ShotButtonBox container gap={1}>
                <Grid item xs={2}>
                  <CntButton variant="outlined" onClick={() => puttState('-')}>
                    -
                  </CntButton>
                </Grid>
                <Grid item xs={6}>
                  <ScoreInput
                    type="number"
                    value={Number(puttCnt)}
                    onChange={(event) => {
                      setPuttCnt(Number(event.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CntButton variant="outlined" onClick={() => puttState('+')}>
                    +
                  </CntButton>
                </Grid>
              </ShotButtonBox>
              <ShotButtonBox container gap={1}>
                {data &&
                  scoreNm[roundInfo[nowHole]?.par]?.putt?.map((shot, i) => {
                    return (
                      <Grid item xs key={shot}>
                        <CntButton
                          key={shot}
                          className={
                            puttCnt !== null
                              ? `dn${puttCnt === i ? ' selected' : ''}`
                              : 'dn'
                          }
                          variant={puttCnt === i ? 'contained' : 'outlined'}
                          onClick={() => puttState(i)}
                        >
                          {shot}
                        </CntButton>
                      </Grid>
                    );
                  })}
              </ShotButtonBox>
            </>
          )}
        </div>
      )}

      {(!playing || !preInputPoint) && (
        <>
          {playerData?.every((d: any) => d.hole_id_list.length >= nowHole) ? (
            <>
              {gameType === 'stroke' &&
                (!savegame ? (
                  <>
                    <HoleOutStroke />
                  </>
                ) : (
                  <>
                    {preInputPoint && (
                      <>
                        <HoleOutInfo style={{ textAlign: 'center' }}>
                          포인트 정산이 완료되었습니다.
                        </HoleOutInfo>
                        <HoleOutInfo>
                          이번 라운드의 승자는{' '}
                          {
                            playerData.sort((a: any, b: any) => {
                              const sumA = a.hole_id_list.reduce(
                                (sum: number, hole: any) => sum + hole.shot,
                                0,
                              );
                              const sumB = b.hole_id_list.reduce(
                                (sum: number, hole: any) => sum + hole.shot,
                                0,
                              );
                              return sumA - sumB;
                            })[0].user_nickname
                          }
                          님 입니다.
                        </HoleOutInfo>
                      </>
                    )}
                  </>
                ))}
              {gameType === 'chips' &&
                ((nowHole === 9 && chipsNextHole) || nowHole === 18 ? (
                  <>
                    {playerData?.map((player: any, i: number) => {
                      const chipList = player.game_data?.chips;
                      return (
                        <HoleOutInfo key={i}>
                          <span>{player.user_nickname}님은 </span>
                          <span>
                            {chipList.length !== 0
                              ? `${chipList.length}개의 칩을 가지고 있어 ${chipList.length}Point를 잃었습니다.`
                              : '칩을 가지고 있지 않습니다.'}
                          </span>
                        </HoleOutInfo>
                      );
                    })}
                    {!owner && chipsNextHole && (
                      <HoleOutInfo>
                        방장이 점수를 정산하고 있습니다 잠시만 기다려주세요.
                      </HoleOutInfo>
                    )}
                  </>
                ) : savegame ? (
                  <>
                    {playerData?.map((player: any, i: number) => {
                      const chipList = player.game_data?.chips;
                      const point = player?.hole_id_list.reduce(
                        (sum: number, item: any) => sum + item.point,
                        0,
                      );
                      return (
                        <HoleOutInfo key={i}>
                          <span>{player.user_nickname}님의 최종스코어는 </span>
                          <span>{point}PT 입니다.</span>
                        </HoleOutInfo>
                      );
                    })}
                    <HoleOutInfo>
                      저장이 완료 되었습니다. <br />
                      게임을 종료해주세요.
                    </HoleOutInfo>
                  </>
                ) : (
                  <HoleOutInfo>
                    <img className="chipInfo" src={chipButtonInfo} alt="info" />
                    <span>
                      상단의 파란 버튼을 눌러 칩을 정산한 후 다음 홀로
                      이동해주세요!
                    </span>
                  </HoleOutInfo>
                ))}
              {gameType === 'drawing' && <HoleOutDraw />}
            </>
          ) : (
            <HoldGame>
              <img src={golinee} alt="logo" />
              다른 플레이어의 홀 아웃을
              <br />
              기다리고 있어요! 잠시만 기다려주세요!
            </HoldGame>
          )}
        </>
      )}

      <GameBottomButton>
        <Grid container gap={1} style={{ flexWrap: 'nowrap' }}>
          <Grid item xs={4}>
            <Bank variant="contained">
              <img src={IconBank} alt="icon" />{' '}
              <BankTxt className={bank === 0 ? 'txt' : 'nm'}>
                {bank === 0 && gameType !== 'drawing'
                  ? 'BANK'
                  : gameType === 'drawing'
                  ? 40 - bank
                  : bank}
              </BankTxt>
            </Bank>
          </Grid>
          <Grid item xs={groupCode !== '0' ? 7 : 8}>
            {playing && preInputPoint ? (
              <HoleButton
                variant="contained"
                disabled={!shotCnt && nowHole + 1 < 18}
                onClick={holeOut}
              >
                Hole Out
              </HoleButton>
            ) : nowHole < roundInfo?.length && nowHole < 18 ? (
              <HoleButton
                variant="contained"
                onClick={holePlay}
                disabled={
                  !(nextHole && !drawingNextHole && (owner || !chipsNextHole))
                }
              >
                {owner && chipsNextHole
                  ? '칩 정산하기'
                  : `${nowHole + 1}Hole Start`}
              </HoleButton>
            ) : !preInputPoint && !savegame ? (
              <HoleButton
                variant="contained"
                onClick={saveGame}
                disabled={
                  (gameType === 'drawing' && drawViewCount !== 4) ||
                  (gameType === 'chips' && !owner) ||
                  (smallestLength !== roundInfo?.length && playerCount !== 1) // || (smallestLength === roundInfo?.length && !owner && gameType !== 'stroke' && gameType !== 'normal')
                }
              >
                라운드 종료 & 결과보기
              </HoleButton>
            ) : (
              <HoleButton
                variant="contained"
                onClick={saveGame}
                disabled={!preInputPoint}
              >
                {!owner ? '클럽하우스로 가기' : '게임 저장 & 클럽하우스로 가기'}
              </HoleButton>
            )}
          </Grid>
          {groupCode !== '0' && (
            <Grid item xs={1}>
              <Rank>
                <img
                  onClick={() => RankingView()}
                  src={IcnRank}
                  alt="ranking"
                />
              </Rank>
            </Grid>
          )}
        </Grid>
      </GameBottomButton>
      {chipView && <SendChip />}
      {rankView && <DimmedRankingView clsView={() => setRankView(false)} />}
    </Container>
  );
}

const OptionOnOff = styled.span`
  margin-left: 8px;
  padding: 5px 6px;
  border-radius: 8px;
  background-color: #e7e9ec;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #b1b8c0;

  &.on {
    background-color: #1fb04b;
    color: #fff;
  }
`;

const ScoreBoard = styled(TableContainer)`
  table * {
    border-width: 0 !important;
  }
  th,
  td {
    padding: 0;
  }

  tr > td > div {
    background-color: #fafafb;
  }
  tr:nth-child(1) > td > div {
    //background-color: #fff4d5;
  }
  tr:nth-child(2) > td > div {
    //background-color: #ebf3ff;
  }
  tr:nth-child(3) > td > div {
    //background-color: #ffece0;
  }

  td:first-child > div {
    border-radius: 30px 0 0 30px;
  }
  td:last-child > div {
    border-radius: 0 30px 30px 0;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const NameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 60px;
  margin: 4px 0;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 500;

  &.me {
    font-weight: bold;
    color: #1fb04b;
  }
`;
const NameImg = styled.img`
  position: absolute;
  top: 24px;
  left: 0;
  width: 20px;
`;
const OECD = styled.span`
  padding: 1px 2px;
  margin-bottom: 3px;
  background-color: #c13537;
  border-radius: 3px;

  font-size: 8px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
`;

const UserOptionList = styled.div`
  position: absolute;
  margin: 100px 0 0 -310px;
  z-index: 510;
  &.n2 {
    margin-top: 170px;
  }
  &.n3 {
    margin-top: 240px;
  }
  &.n4 {
    margin-top: 310px;
  }
`;

const PointWrap = styled.div`
  height: 60px;
  padding: 8px 4px;
`;
const ShotWrap = styled.div`
  height: 60px;
  padding: 8px 4px;
`;
const TotalWrap = styled.div`
  height: 60px;
  padding: 8px;
`;

const PointBox = styled.div`
  height: 44px;
  border-radius: 8px;
  background-color: #e0ebfc;
  font-size: 18px;
  font-weight: bold;
  line-height: 44px;
  color: #3162d2;
`;

const ShotBox = styled.div`
  position: relative;
  width: 44px;
  height: 44px;
  padding-top: 2px;
  border-radius: 8px;
  background-color: #e7e9ec;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  &.playHole {
    background-color: #e0fce5;
    color: #1fb04b;
  }
  &.under {
    background-color: #f9e7e9;
    color: #c13537;
  }
  & > .parInfo {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #505866;
  }
`;

const OECDBox = styled(Grid)`
  margin-top: 16px;
`;
const OECDList = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 12px 2px;
  border-radius: 8px;
  background-color: #fafafb;

  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #505866;

  &.on {
    font-weight: bold;
    color: #fff;

    &:nth-child(1) {
      background-color: #03b6e7;
    }
    &:nth-child(2) {
      background-color: #603813;
    }
    &:nth-child(3) {
      background-color: #e64c3c;
    }
    &:nth-child(4) {
      background-color: #59d316;
    }
    &:nth-child(5) {
      background-color: #477ef9;
    }
  }
`;

const TotalBox = styled.div`
  height: 44px;
  border-radius: 8px;
  background-color: #1fb04b;

  font-size: 18px;
  font-weight: bold;
  line-height: 44px;
  color: #fff;
`;

const Putt = styled.div`
  position: absolute;
  margin: 0;
  padding: 1px 5px;
  right: 0;
  bottom: 4px;
  border: 1px solid #ccc;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  text-align: center;

  &.total {
    bottom: 20px;
    right: 10px;
  }
`;

const StyledTable = styled(Table)`
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
  }
  th:first-child {
    border-left: 1px solid #ccc;
    z-index: 4;
  }
  td:first-child {
    border-left: 1px solid #ccc;
    background-color: #fff;
    z-index: 3;
  }

  tr:first-child > th {
    border-top: 1px solid #ccc;
  }

  * {
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
`;

const HoleInfo = styled(Typography)`
  display: flex;
  align-items: center;
  margin: 20px 0 15px;
`;

const AllsquareDouble = styled.span`
  padding: 2px 4px 3px;
  margin-left: 5px;
  border-radius: 4px;
  background-color: #f0bc40;

  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
`;

const SelectedPlayer = styled.span`
  padding: 2px 4px 3px;
  margin-left: 5px;
  border-radius: 4px;
  background-color: #505866;

  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
`;

const ShotButtonBox = styled(Grid)`
  display: flex;
  justify-content: space-evenly;
  margin: 5px 0;
  flex-wrap: nowrap;

  & button {
    width: 100%;
  }
`;

const ScoreInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  border: solid 1px #1fb04b;
  background-color: #e0fce5;

  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #1fb04b;
`;

const CntButton = styled(Button)`
  height: 36px;
  min-width: unset;
  padding: 6px 0;
  border-radius: 8px;
  border-width: 0;
  background-color: #fafafb;
  box-sizing: border-box;

  font-size: 20px;
  font-weight: bold;
  color: #1a1e27;

  &.dn {
    font-size: 14px;
    font-weight: bold;
    color: #6d7582;

    &.selected {
      border: solid 1px #1fb04b;
      background-color: #e0fce5;
      color: #1fb04b;
    }
  }
`;

const HoldGame = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 18px 18px 36px;
  margin: 16px 0;
  border-radius: 36px;
  background-color: #fafafb;

  font-size: 14px;
  font-weight: 500;
  color: #0f0f0f;

  img {
    margin-right: 16px;
  }
`;

const Bank = styled(Button)`
  width: 100%;
  height: 48px;
  background-color: #f0bc40;

  img {
    margin-right: 2px;
    width: 32px;
  }
  &:hover {
    background-color: #f0bc40;
  }
`;

const Rank = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 2px;
  background-color: #e0fce5;
`;

const BankTxt = styled.span`
  width: 50%;

  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  &.nm {
    font-size: 24px;
  }
`;

const HoleButton = styled(Button)`
  width: 100%;
  height: 48px;
  text-transform: none;
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;

  &.transparent {
    background-color: #0000;
  }
`;

const ChipBox = styled.div`
  position: relative;
  max-width: 460px;
  width: 100%;
  padding: 24px;
  border-radius: 24px 24px 0 0;
  background-color: #fff;
`;

const Chip = styled.div`
  display: inline-block;
  padding: 8px 5px 3px;
  border-radius: 12px;
  //background-color: #f2f3f5;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01px;
  text-align: center;
  color: #0f0f0f;
  img {
    width: 72px;
  }
`;

const ImgClose = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const ChipList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  height: 230px;
`;

const ChipInfo = styled.div`
  margin: 8px;
  padding: 6px;
  border-radius: 8px;
  background-color: #e0fce5;
  color: #1fb04b;

  text-align: center;
`;

const OptionListTable = styled(Table)`
  position: absolute;
  width: 152px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e9ec;
  background-color: #fff;

  tr > td {
    padding: 10px 14px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #666;
  }

  img {
    width: 24px;
    margin-right: 4px;
  }

  tr:first-child > td {
    padding: 5px 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
`;

const HoleOutInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 17px 24px;
  margin-bottom: 8px;
  border-radius: 32px;
  background-color: #fafafb;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;

  &.oecd {
    margin-top: 24px;
    background-color: #fff3d8;
    font-weight: bold;
    color: #f0bc40;
    text-align: center;
  }

  .o {
    font-weight: bold;
    color: #c13537;
  }
  .g {
    font-weight: bold;
    color: #1fb04b;
  }

  &.total {
    text-align: center;
    border: 1px solid;
    &.o {
      border-color: #c13537;
    }
    &.g {
      border-color: #1fb04b;
    }
  }

  img {
    width: 300px;

    &.chipInfo {
      width: 60px;
      margin-right: 16px;
    }
  }
`;

const StrokePointInfo = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StrokePlayerList = styled.div`
  width: calc(75% - 8px);
  padding: 17px 12px;
  border-radius: 16px;
  background-color: #fafafb;
  font-size: 13px;
  font-weight: 500;
  color: #0f0f0f;
`;

const PointStatus = styled.div`
  width: 25%;
  margin-left: 8px;
  padding: 17px 5px;
  border-radius: 16px;
  background-color: #fafafb;

  text-align: center;

  .o {
    font-weight: bold;
    color: #c13537;
  }
  .g {
    font-weight: bold;
    color: #1fb04b;
  }
`;

const GameBottomButton = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 14px 20px 34px;
  max-width: 460px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.71) 39%,
    #fff
  );
  z-index: 100;
`;

const TeamBox = styled(Grid)`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PlayerList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: #fff;

  &:first-child {
    margin-bottom: 8px;
  }
`;

const PlayerBox = styled.div`
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 8px;
  &.team1 {
    background-color: #0bc49a;
  }
  &.team2 {
    background-color: #ff9500;
  }
`;

const PlayerName = styled.div`
  width: 55%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: left;

  &.me {
    color: #1fb04b;
  }
`;

const PlayerPoint = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
`;

const Result = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 8px 8px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  &.team1 {
    background-color: #e1fcf6;
    color: #0bc49a;
  }
  &.team2 {
    background-color: #fcf1e1;
    color: #ff9500;
  }
`;

const TeamPoint = styled.div`
  width: 100%;
  padding: 10px 8px;
  border-radius: 8px;
  background-color: #fff;
`;
