import React, { useEffect } from 'react';
import styled from 'styled-components';
import logo from './assets/images/golfun_w.svg';

function Redirect() {
  useEffect(() => {
    window.location.href = window.location.origin + '/Splash?ver=1';
  }, []);

  return (
    <MainWrap>
      <LogoImg src={logo} alt="logo" />
    </MainWrap>
  );
}

export default Redirect;

const MainWrap = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const LogoImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 30%);
  max-width: 350px;
`;
